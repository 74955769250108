import { template as template_9d5b3c1891b9491bb73462b9eac35e20 } from "@ember/template-compiler";
const FKLabel = template_9d5b3c1891b9491bb73462b9eac35e20(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
